import React from 'react';
import ShortcutPage from '../components/ShortcutPage';

interface ContainerProps {
  isFullScreen: boolean;
}
const GamesTab: React.FC<ContainerProps> = ({isFullScreen}) => {
  return (
    <ShortcutPage KEY="GAMES" isFullScreen={isFullScreen} />
  )

};

export default GamesTab;
