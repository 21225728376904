import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonFab,
  IonFabButton,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { appsOutline, expandOutline, gameController, moonOutline, musicalNotesOutline, sunnyOutline, videocam } from 'ionicons/icons';
import VideosTab from './pages/VideosTab';
import GamesTab from './pages/GamesTab';
import MusicTab from './pages/MusicTab';
import OthersTab from './pages/OthersTab';
import Storage from './utilities/Storage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import React, { useEffect, useState } from 'react';

setupIonicReact();

const App: React.FC = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let isFullScreen = false;

  if (params["data"] !== undefined) {
    console.log(params["data"]);
    Storage.import_all(params["data"]);
    isFullScreen = true;
    localStorage.setItem("isFullScreen", "1");
  }

  isFullScreen = localStorage.getItem("isFullScreen") === "1";

  if (localStorage.getItem("isNightMode") === null) {
    localStorage.setItem("isNightMode", "0");
  }
  const [isNightMode, setIsNightMode] = useState(parseInt(localStorage.getItem("isNightMode") || "0"));
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isNightMode && isFirstRender) {
      document.body.classList.toggle('dark');
    }
    setIsFirstRender(false);
  });

  //prepopulate the shortcuts
  let is_first_used = localStorage.getItem("first_used") === null;
  if (is_first_used) {
    localStorage.setItem("first_used", "false");
    let video_tab_shortcuts = [
      {
        "name": "YouTube", "url": "https://www.youtube.com/"
      },
      {
        "name": "Hulu", "url": "https://www.hulu.com/"
      },
      {
        "name": "Twitch", "url": "https://www.twitch.com/"
      },
      {
        "name": "Prime Video", "url": "https://www.primevideo.com/"
      },
      {
        "name": "iPlayer", "url": "https://www.bbc.co.uk/iplayer"
      },
      {
        "name": "Apple TV+", "url": "https://www.apple.com/uk/apple-tv-plus/"
      },
      {
        "name": "Pluto TV", "url": "https://pluto.tv/"
      },
      {
        "name": "Now TV", "url": "https://www.nowtv.com/watch-now-tv"
      },
      {
        "name": "ITV", "url": "https://www.itv.com/"
      },
    ]
    let music_tab_shortcuts = [
      {
        "name": "Apple Music", "url": "https://www.apple.com/apple-music/"
      },
      {
        "name": "Amazon Music", "url": "https://music.amazon.com/"
      }
    ]
    let game_tab_shortcuts = [
      {
        "name": "Stadia", "url": "https://stadia.google.com/"
      },
      {
        "name": "Geforce Now", "url": "https://play.geforcenow.com"
      },
      {
        "name": "XBOX Cloud", "url": "https://www.xbox.com/en-US/play/gallery/all-games"
      },
      {
        "name": "Webrcade", "url": "https://play.webrcade.com/"
      },
      {
        "name": "Emulator", "url": "https://emulatorgames.online/"
      }

    ]
    let other_tab_shortcuts = [
      {
        "name": "Waze", "url": "https://www.waze.com/en-GB/live-map"
      },
      {
        "name": "Plug Share", "url": "https://www.plugshare.com/"
      },
      {
        "name": "Zap Map", "url": "https://www.zap-map.com/live/"
      },
      {
        "name": "Pod Point", "url": "https://charge.pod-point.com/"
      },
      {
        "name": "Google Maps", "url": "https://www.google.com/maps"
      },
      {
        "name": "Route Planner", "url": "https://abetterrouteplanner.com/"
      },
      {
        "name": "TripAdvisor", "url": "https://www.tripadvisor.co.uk/"
      }
    ]
    localStorage.setItem("VIDEOS", JSON.stringify(video_tab_shortcuts));
    localStorage.setItem("MUSIC", JSON.stringify(music_tab_shortcuts));
    localStorage.setItem("GAMES", JSON.stringify(game_tab_shortcuts));
    localStorage.setItem("OTHERS", JSON.stringify(other_tab_shortcuts));
  }

  const toggle_night_mode = () => {
    document.body.classList.toggle('dark');
    if (isNightMode === 1) {
      setIsNightMode(0);
      localStorage.setItem("isNightMode", "0");
    }
    else {
      setIsNightMode(1);
      localStorage.setItem("isNightMode", "1");
    }
  }

  const toggle_full_screen = () => {
    let data = Storage.export_all();
    window.open("https://www.youtube.com/redirect?q=" + window.location.href + "?data=" + data);
  }

  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/videos">
              <VideosTab isFullScreen={isFullScreen} />
            </Route>
            <Route exact path="/games">
              <GamesTab isFullScreen={isFullScreen} />
            </Route>
            <Route path="/music">
              <MusicTab isFullScreen={isFullScreen} />
            </Route>
            <Route path="/others">
              <OthersTab isFullScreen={isFullScreen} />
            </Route>
            <Route exact path="/">
              <Redirect to="/videos" />
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="videos" href="/videos">
              <IonIcon icon={videocam} />
              <IonLabel>Videos</IonLabel>
            </IonTabButton>
            <IonTabButton tab="games" href="/games">
              <IonIcon icon={gameController} />
              <IonLabel>Games</IonLabel>
            </IonTabButton>
            <IonTabButton tab="music" href="/music">
              <IonIcon icon={musicalNotesOutline} />
              <IonLabel>Music</IonLabel>
            </IonTabButton>
            <IonTabButton tab="others" href="/others">
              <IonIcon icon={appsOutline} />
              <IonLabel>Others</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
        {!isFullScreen ? <IonFab vertical="top" horizontal="end" slot="fixed">
          <IonFabButton className="margin_bottom_30px" onClick={toggle_full_screen}>
            <IonIcon icon={expandOutline} />
          </IonFabButton>
          <IonFabButton className="margin_bottom_30px" onClick={toggle_night_mode}>
            <IonIcon icon={isNightMode ? sunnyOutline : moonOutline} />
          </IonFabButton>
        </IonFab> : <React.Fragment></React.Fragment>}
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
