import React from 'react';
import ShortcutPage from '../components/ShortcutPage';

interface ContainerProps {
  isFullScreen: boolean;
}
const MusicTab: React.FC<ContainerProps> = ({isFullScreen}) => {
  return (
    <ShortcutPage KEY="MUSIC" isFullScreen={isFullScreen} />
  )

};

export default MusicTab;
