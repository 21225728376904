import { IonButton, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar } from "@ionic/react"
import { useState } from "react";


interface ContainerProps {
    add_shortcut: any;
}

const AddNewShortcut: React.FC<ContainerProps> = ({ add_shortcut }) => {
    const [name, setName] = useState("");
    const [url, setUrl] = useState("https://");


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Add New Shortcut</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem>
                        <IonLabel position="floating">Name</IonLabel>
                        <IonInput value={name} onIonChange={e => setName(e.detail.value!)}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">URL</IonLabel>
                        <IonInput value={url} onIonChange={e => setUrl(e.detail.value!)}></IonInput>
                    </IonItem>
                </IonList>
                <IonButton expand="block" color="primary" onClick={e => {
                    e.preventDefault();
                    add_shortcut(name, url);
                }}>Add</IonButton>
            </IonContent>
        </IonPage>
    );
};

export default AddNewShortcut;