module Storage {
    export const add_shortcut = (key:string, shortcut:any) => {
        let current_shortcut = get_shortcut(key);
        current_shortcut.push(shortcut);
        localStorage.setItem(key, JSON.stringify(current_shortcut));
        return current_shortcut;
    }
    export const get_shortcut = (key:string) => {
        let content = localStorage.getItem(key) || "[]";
        return JSON.parse(content);
    }

    export const export_all = () => {
        let keys = Object.keys(localStorage);
        let result:any = {};
        keys.forEach((key:string) => {
            let item = JSON.parse(localStorage.getItem(key)||"{}");
            result[key] = item;
        }) 
        return JSON.stringify(result)
    }

    export const import_all = (data:string) => {
        let result = JSON.parse(data.replaceAll("\'",""));
        Object.keys(result).forEach((key:string) => {
            localStorage.setItem(key, JSON.stringify(result[key]));
        })
    }
    
    export const move_shortcut_to_top = (key:string, shortcut_name:string) => {
        let current_shortcut = get_shortcut(key);
        let selected_shorcut = {}
        for (let i = 0; i < current_shortcut.length; i++) {
            if (current_shortcut[i]["name"]===shortcut_name) {
                selected_shorcut = current_shortcut[i];
                current_shortcut.splice(i, 1);
            }   
          }
        current_shortcut = [selected_shorcut, ...current_shortcut];
        localStorage.setItem(key, JSON.stringify(current_shortcut));
        return current_shortcut;
    }


    export const remove_shortcut = (key:string, shortcut:any) => {
        let current_shortcut = get_shortcut(key);
        for (let i = 0; i < current_shortcut.length; i++) {
            
            if (current_shortcut[i]["name"]===shortcut["name"]) {
                current_shortcut.splice(i, 1);
            }   
          }
        localStorage.setItem(key, JSON.stringify(current_shortcut));
        return current_shortcut;
    }
}

export default Storage;