import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonIcon, IonModal, IonActionSheet } from '@ionic/react';
import React, { useState } from 'react';
import Storage from '../utilities/Storage';
import { addCircleOutline } from 'ionicons/icons';
import AddNewShortcut from './AddNewShortcut';
import { useLongPress } from 'use-long-press';

interface ContainerProps {
  isFullScreen: boolean;
  KEY: string;
}

const ShortcutPage: React.FC<ContainerProps> = ({ isFullScreen, KEY }) => {
  let current_shortcuts = Storage.get_shortcut(KEY) || [];
  const [shortcuts, setShortcuts] = useState(current_shortcuts);
  const [showModal, setModal] = useState(false);
  const [showAction, setAction] = useState(false);
  const [actionName, setActionName] = useState("");

  const show_modal = (e: any) => {
    e.preventDefault();
    setModal(true);
  }

  const open_url = (e: any) => {
    e.preventDefault();
    let url = e.target.href;
    setShortcuts(Storage.move_shortcut_to_top(KEY, e.target.innerText));
    window.open(url);
  }

  const add_shortcut = (name: string, url: string) => {
    let new_shortcuts = Storage.add_shortcut(KEY, { "name": name, "url": url });
    setShortcuts(new_shortcuts);
    setModal(false);
  }

  const show_action_list = useLongPress((e) => {
    e?.preventDefault();
    if (!isFullScreen) {
      let target = e?.target as HTMLAnchorElement;
      setActionName(target.innerText);
      setAction(true);
    }
  }, { captureEvent: true });

  const delete_shortcut = () => {
    shortcuts.forEach((o: any) => {
      if (actionName === o["name"]) {
        setShortcuts(Storage.remove_shortcut(KEY, o));
      }
    });
  }


  return (
    <IonPage>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            {
              shortcuts.map((shortcut: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <IonCol size="3"><span className="shortcut"><a onClick={open_url} href={shortcut["url"]} {...show_action_list}>{shortcut["name"]}</a></span></IonCol>
                  </React.Fragment>
                )
              })
            }
            {
              !isFullScreen ? <IonCol size="3"><span className="shortcut plus-button"><a href="/" onClick={show_modal}><IonIcon icon={addCircleOutline} /></a></span></IonCol> : <React.Fragment></React.Fragment>
            }

          </IonRow>
        </IonGrid>
        <IonModal
          isOpen={showModal}
          swipeToClose={false}
          onDidDismiss={() => setModal(false)}>
          <AddNewShortcut add_shortcut={add_shortcut} />
        </IonModal>
        <IonActionSheet
          isOpen={showAction}
          onDidDismiss={(e) => {
            setAction(false);
            if (e.detail.data === undefined) {
              return;
            }
            if (e.detail.data.type === 'delete') {
              delete_shortcut();
            }
          }}
          buttons={[
            {
              text: 'Delete',
              role: 'destructive',
              id: 'delete-button',
              data: {
                type: 'delete'
              },
              handler: () => {

              }
            }
          ]}
        >
        </IonActionSheet>
      </IonContent>
    </IonPage>
  );
};

export default ShortcutPage;
